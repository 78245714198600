import { HttpRequest } from '../../helpers/HttpRequest';

const Areas = (dict_user_access) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_areas?dict_user_access=${dict_user_access}`,
    method: "GET",
    headers: {
      'x-functions-key': process.env.REACT_APP_AREAS_AZURE_TOKEN,
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
      'X-XSS-Protection': '1; mode=block',
      'Feature-Policy': "camera 'none'; microphone 'none'",
      'Content-Security-Policy': "default-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline';"
    }
  }
  return HttpRequest.request(requestOptions)
}

const Zonas = (area_id) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_thickness_wear_rate_area?id_area=${area_id}`,
    method: "GET",
    headers: {
      'x-functions-key': process.env.REACT_APP_ZONAS_AZURE_TOKEN,
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
      'X-XSS-Protection': '1; mode=block',
      'Feature-Policy': "camera 'none'; microphone 'none'",
      'Content-Security-Policy': "default-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline';"
    }
  }
  return HttpRequest.request(requestOptions)
}

const Sensores = (zona_id) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_devices?id_zone=${zona_id}`,
    method: "GET",
    headers: {
      'x-functions-key': process.env.REACT_APP_SENSORES_AZURE_TOKEN,
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
      'X-XSS-Protection': '1; mode=block',
      'Feature-Policy': "camera 'none'; microphone 'none'",
      'Content-Security-Policy': "default-src 'self'; script-src 'self' 'unsafe-inline'; style-src 'self' 'unsafe-inline';"
    }
  }
  return HttpRequest.request(requestOptions)
}

export const MainServices = {
  Areas,
  Zonas,
  Sensores
}