import React, { useRef } from "react";
import { Box } from "@mui/material";
import MainBar from '../../MainBar';
import Footer from '../../Footer';
import Mantencion from '../../../../assets/img/mantencion/Mantención.svg';

const EnMantencion = () => {

  const imgRef = useRef(null);
  const circleRef = useRef(null);

  const handleMouseMove = (e) => {
    const img = imgRef.current;
    const circle = circleRef.current;
    const { left, top, width, height } = img.getBoundingClientRect();
    const x = e.clientX - (left + width / 2);
    const y = e.clientY - (top + height / 2);

    // Adjust image tilt based on mouse position
    img.style.transform = `rotateX(${y / 20}deg) rotateY(${x / 20}deg)`;

    // Apply opposite tilt to the background circle
    circle.style.transform = `rotateX(${-y / 30}deg) rotateY(${-x / 30}deg)`;

    // Change the shape of the circle based on mouse position
    const borderRadiusX = 50 + x / 10; // Adjust to control ovalness in X direction
    const borderRadiusY = 50 + y / 10; // Adjust to control ovalness in Y direction
    circle.style.borderRadius = `${borderRadiusY}% ${borderRadiusX}%`;
    circle.style.transition = "transform 0.1s ease, border-radius 0.2s ease"; // Faster transition while moving
  }

  const resetTilt = () => {
    const circle = circleRef.current;
    const img = imgRef.current;

    // Reset transformations for both image and circle
    img.style.transform = "rotateX(0) rotateY(0)";
    circle.style.transform = "rotateX(0) rotateY(0)";

    // Slowly transition back to a perfect circle shape
    circle.style.transition = "border-radius 1s ease-out, transform 0.5s ease-out";
    circle.style.borderRadius = "50%";
  }

  return (
    <React.Fragment>
      <MainBar />
      <Box style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundColor: "#2357BC", display: "flex", justifyContent: "center", alignItems: "center",  position: "relative", overflow: "hidden" }} >

      <div ref={circleRef} style={{ position: "absolute", width: "800px", height: "800px", backgroundColor: "#fff", borderRadius: "50%", transition: "transform 0.1s ease, border-radius 0.2s ease", zIndex: 1 }} />
      <img
        ref={imgRef}
        src={Mantencion}
        alt="Tilted Image"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          transition: "transform 0.1s ease",
          cursor: "pointer",
          position: "relative",
          zIndex: 2,
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={resetTilt}
      />

      </Box>
      <Footer />
    </React.Fragment>
  );
}
 
export default EnMantencion;