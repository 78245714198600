import React, { useState } from "react";
import { Grid, Box, Button, Typography, Paper, TextField, Hidden, Link, InputAdornment } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useToast } from 'rc-toastr';
import * as gen_func from '../../helpers/general_functions';
import LoadingButton from '@mui/lab/LoadingButton';
//Imagenes
import LoginBG from '../../assets/img/LoginBg.png';
import MainPic from '../../assets/img/Imagen_Login.png';
import LogoIcon from '../../assets/img/Logo ME Tracking.png';
//Icons
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';

/**@constant styles Contiene los estilos para los contenedores */
const styles = {
  paperContainer: {
      backgroundImage: `url(${LoginBG})`,
      backgroundSize: 'cover',
  }
};

/**@constant CssTextField Contiene los estilos para los campos de texto */
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
      
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
        height: "50px"
      },
      '&:hover fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
      },
    },
  },
})(TextField);

const LoginForm = () => {

  /**variable */
  const { toast } = useToast();
  const [username, set_username] = useState('');
  const [password, set_password] = useState('');
  const [LoginTried, setLoginTried] = useState(false);

  /**
   * Controla el textbox usuario
   * @param {Object} e 
   */
  const onChangeUsername = (e) => {
    set_username(e.target.value)
  }

  /**
   * Controla el logeo del usuario a la pagina
   * @param {Object} event 
   */
  const handleLogin = (event) => {
    event.preventDefault();
    if (username !== '' && password !== '') {
      setLoginTried(true)
      const myHeaders = new Headers();
      myHeaders.append("x-functions-key", process.env.REACT_APP_LOGIN_FUNCTION_KEY);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("id_system", process.env.REACT_APP_APP_ID);
      urlencoded.append("username", username);
      urlencoded.append("password", password);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      fetch("https://func-admin-users-prod-001.azurewebsites.net/api/login", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 'disabled') {
            toast.error("Su usuario no tiene acceso. Por favor comuniquese con su proveedor.")
            setLoginTried(false)
          }else if (!result.status) {
            toast.error("Usuario o contraseña incorrectos.")
            setLoginTried(false)
          }else{
            if (result.access_level) {
              localStorage.setItem('faena_id',JSON.stringify(result.access_level.id))
              localStorage.setItem('pais_id',JSON.stringify(result.access_level.pais_id))
              localStorage.setItem('operador_id',JSON.stringify(result.access_level.operador_id))
            }else{
              localStorage.setItem('faena_id',JSON.stringify([]))
              localStorage.setItem('pais_id',JSON.stringify([]))
              localStorage.setItem('operador_id',JSON.stringify([]))
            }
            localStorage.setItem('system_enabled',result.system_enabled)
            localStorage.setItem('access_type',result.access_type)
            gen_func.encryptData(process.env.REACT_APP_LOCAL_STORAGE_ACCESS_TOKEN, Math.random().toString(36).slice(2))
            window.location.reload()
          }
        })
        .catch((error) => {
          toast.error("El usuario no existe.")
          setLoginTried(false)
        });
    }else{
      toast.error("No pueden quedar campos vacios.")
    }
  }
  
  return (
    <React.Fragment>
      
      <Grid container style={{ backgroundColor: "#203A88" }}>
        <Hidden smDown>
          <Grid item xl={8} lg={8} md={8} sm={0} xs={0}>
            <Paper style={{ backgroundImage: `url(${MainPic})`, backgroundSize: 'cover', }}>
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" style={{ color: "#fff" }}></Box>
            </Paper>
          </Grid>
        </Hidden>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Paper style={styles.paperContainer}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" style={{ color: "#fff" }}>

              <Grid container>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                  {/**Idioma */}
                  </Box>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center"}}>
                  <img src={LogoIcon} style={{ width: "60%" }}/>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center", marginTop: "30px", marginBottom: "20px"}}>
                  <Typography variant="h5" gutterBottom style={{ margin: '-2px 0px -2px 0px' }}>
                    ¡Inicia sesión con
                  </Typography>
                  <Typography variant="h5" gutterBottom style={{ margin: '-2px 0px -2px 0px' }}>
                    tu cuenta!
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <form onSubmit={handleLogin}>

                    <Grid container>
                      <Grid item xs={12} style={{textAlign: "center"}}>
                        <CssTextField label="Email" type="text" margin="normal" autoComplete='off'
                          sx={{ width: 350, height: 50, input: { color: 'white' } }}
                          onChange={(e) => onChangeUsername(e)}
                          InputLabelProps={{ style: { color: 'white', marginTop: "-4px" } }}
                          InputProps={{
                            style: { color: 'white' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <EmailIcon style={{ color: "#fff" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{textAlign: "center"}}>
                        <CssTextField label="Contraseña" type="password" margin="normal" autoComplete='off'
                          sx={{ width: 350, height: 50, input: { color: 'white' } }}
                          onChange={(e) => set_password(e.target.value)}
                          InputLabelProps={{ style: { color: 'white', marginTop: "-4px" } }}
                          InputProps={{
                            style: { color: 'white' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <LockIcon style={{ color: "#fff" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} style={{textAlign: "center", marginTop: "5px"}}>
                        <Typography variant="subtitle1">
                          <Link href="/recover_form" style={{ color: "#fff" }}>¿Olvidó su contraseña?</Link>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{textAlign: "center", marginTop: "30px"}}>
                        { !LoginTried ? (
                          <Button type="submit" variant="contained" style={{ backgroundColor: "#fff", color: "#1D57B8", padding: "18px 50px", width: "200px", textTransform: 'none' }}>
                            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Iniciar sesión</Typography>
                          </Button>
                        ):(
                          <LoadingButton variant="contained" disabled loading={true} loadingPosition="end" style={{ backgroundColor: "#fff", color: "#1D57B8", padding: "18px 50px", textTransform: 'none', width: "200px", }}>
                            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Cargando</Typography>
                          </LoadingButton>
                        )}
                      </Grid>

                    </Grid>

                  </form>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Typography style={{ color: "#B6C7E9", textAlign: 'center', fontSize: 12 }}>Powered by ME Elecmetal / v1.1</Typography>
                  </Box>
                </Grid>

              </Grid>

            </Box>
          </Paper>
        </Grid>
      </Grid>
      
    </React.Fragment>
  );
}
 
export default LoginForm;